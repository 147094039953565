/* eslint-env browser */
import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState
} from 'react';
import io from 'socket.io-client';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectSocketAddress,
  changeSocketConnected
} from '../redux/settingsSlice';
import {
  selectJudge,
  updateJudgesList,
  selectSocketsCache,
  updateTask,
  updateTaskManagerQueue,
  updateTaskResults,
  updateParticipantResults,
  clearParticipantResultsForRound,
  selectAllTaskResults
} from '../redux/workSlice';
import { selectDeviceUuid, selectDeviceId } from '../redux/settingsSlice';

export const SocketContext = createContext();

export const SocketProvider = ({ children, store }) => {
  const [isConnected, setConnected] = useState(false);

  //   const socketUrl = `${process.env.API_URL}/socket.io`;

  const socket = useRef(null);
  const dispatch = useAppDispatch();
  const handleOnMessage = (message) => {
    // console.log(message);
    // store.dispatch here
  };
  useEffect(() => {
    dispatch(changeSocketConnected(isConnected));
  }, [isConnected]);

  const socketUrl = useAppSelector(selectSocketAddress);
  const socketsCache = useAppSelector(selectSocketsCache);
  const chosenJudge = useAppSelector(selectJudge);
  const uuid = useAppSelector(selectDeviceUuid);
  const deviceId = useAppSelector(selectDeviceId);
  useEffect(() => {
    // console.log('socket-event');
    if (!isConnected) {
      socket.current = io(socketUrl, {
        transports: ['websocket'],
        rejectUnauthorized: false
      });
      socket.current.on('connect', () => {
        console.info(`Successfully connected to socket at ${socketUrl}`);
        // console.info(1);
        // debugger;
        const adjudicatorSocket = {
          action: 'adjudicator',
          args: [
            {
              type: 'adjudicatorLogIn',
              letter: chosenJudge.letter,
              name: chosenJudge.name,
              adjudicatorUuid: chosenJudge.uuid,
              socketId: socket.current.id,
              deviceUuid: uuid,
              deviceId: deviceId
            }
          ],
          acknowledge: false,
          volatile: false
        };
        socket.current.emit(
          adjudicatorSocket.action,
          ...adjudicatorSocket.args
        );
        socketsCache.forEach((s) => {
          // console.log(s);
          if (s.action === 'adjudicator') {
            s.socketId = socket.current.id;
          }
          if (s.volatile) {
            socket.current.volatile.emit(s.action, ...s.args);
          } else {
            socket.current.emit(s.action, ...s.args);
          }
        });
        setConnected(true);
      });
      // socket.current.onAny((event, ...args) => {
      //   console.log(`s got ${event}`);
      // });
      socket.current.on('disconnect', () => {
        // console.info(`Successfully disconnected`);
        setConnected(false);
      });

      socket.current.on('message', handleOnMessage);

      socket.current.on('judgesList', (arg) => {
        dispatch(updateJudgesList(arg));
      });
      socket.current.on('adjudicatorSheet', (task) => {
        // console.log('judgesSheetReceived', task);
        dispatch(updateTask(task));
        dispatch(updateTaskResults(task.taskResults));
        if (task.taskResults.participantResults.length === 0) {
          dispatch(clearParticipantResultsForRound(task));
        } else {
          dispatch(
            updateParticipantResults(task.taskResults.participantResults)
          );
        }

        socket.current.emit('ackAdjudicatorSheet', {
          type: 'ackAdjudicatorSheet',
          uuid: task.uuid,
          adjudicatorUuid: chosenJudge.uuid,
          roundUuid: task.roundUuid
        });
      });
      socket.current.on('taskManagerQueue', (queue) => {
        console.log('taskManagerQueue', queue);
        dispatch(updateTaskManagerQueue(queue));
      });
    }
    socket.current.on('error', (err) => {
      console.log('Socket Error:', err.message);
    });
    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.disconnect();
      }
    };
  }, [socketUrl]);

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
