import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { v4 as uuidv4 } from 'uuid';
import SYSTEM, { SystemType } from '../constants/judgingSystems';
import { SheetType } from '../constants/sheetStatus';
import TASK, { TaskType } from '../constants/taskStatus';
import SOCKET, { SocketType } from '../constants/socketTypes';
import { createAppAsyncThunk } from '../app/hooks';

export interface SocketElement {
  action: string;
  args: Array<any>;
  room?: string | null;
  callback?: string | null;
  namespace?: string | null;
  acknowledge: boolean;
  volatile: boolean;
  uuid?: string;
}
export interface CurrentScreenSocket {
  adjudicator: string;
  taskUuid: string;
  category: string;
  round: string;
  dance: Dance;
  participant: TaskParticipant | null;
}
export interface Task {
  uuid: string;
  type: SocketType;
  roundUuid: string;
  adjudicatorUuid: string;
  ttItemUuid: string | null;
  judgingSystem: SystemType;
  participants: TaskParticipant[];
  dances: Dance[];
  heats: Heat[];
  sheet: SheetType;
  status: string;
  taskResults: TaskResults;
  round: Round;
  category: any;
}
export interface TaskParticipant {
  uuid: string;
  entryUuid: string;
  startNo: number;
}
export interface ParticipantResult {
  uuid: string;
  roundUuid: string;
  danceUuid: string;
  judgingSystem: SystemType;
  result: any;
}

export interface TaskResults {
  uuid: string;
  roundUuid: string;
  ttItemUuid: string | null;
  judgingSystem: SystemType;
  participantResults: ParticipantResult[];
  status: TaskType;
  adjudicatorUuid: string;
}
export interface ManagerItem {
  uuid: string;
  competitionUuid: string;
  roundUuid: string;
  active: boolean;
  nextActive: boolean;
  index: number;
  paused: boolean;
  name: string;
}
export interface RoundParticipant {
  uuid: string;
  entryUuid: string;
  roundUuid: string;
  startNo: number;
  started: boolean;
  starsLeft: number;
  starParticipant: boolean;
  notStartedReason: null | 'excused' | 'no_show';
  notFinished: boolean;
  notFinishedReason: null | 'disqualified' | 'resigned' | 'health';
  name: string;
}
export interface RoundParticipantShort {
  uuid: string;
  entryUuid: string;
  roundUuid: string;
}
export interface HeatParticipant {
  uuid: string;
  entryUuid: string;
  roundUuid: string;
  startNo: number;
  name?: string;
}
export interface Round {
  categoryUuid: string;
  dances: Dance[];
  expectedNumber: number;
  groupDanceTime: number;
  heatType: string;
  heats: number;
  maxInHeat: number;
  index: number;
  isFinal: boolean;
  isPreliminary: boolean;
  isRedance: boolean;
  judgingSystem: SystemType;
  markedCouples: number;
  number: number;
  redance: boolean;
  roundName: string;
  soloDanceTime: number;
  stars: number;
  state: string;
  uuid: string;
  roundParticipants: Array<RoundParticipant>;
  judgedCriteria: number;
}
export interface Heat {
  uuid: string;
  roundUuid: string;
  danceUuid: string;
  participants: HeatParticipant[];
  solo: boolean;
  heatNo: number;
  danceHeatNo: number;
}
export interface Dance {
  desig: string;
  index: number;
  name: string;
  roundIndex: number;
  solo: boolean;
  style: string;
  uuid: string;
}
export interface WorkState {
  timetable: object;
  judgeLetter: string;
  judgeName: string;
  judgeUuid: string;
  currentTask: string | null;
  nextTask: string | null;
  taskManagerQueue: Array<ManagerItem>;
  tasks: Array<Task>;
  tasksCompleted: Array<Task>;
  tasksResults: Array<TaskResults>;
  participantResults: any;
  judgesList: object;
  socketsCache: Array<SocketElement>;
  socketsSent: Array<SocketElement>;
}
const initialState: WorkState = {
  timetable: {},
  judgeLetter: '',
  judgeName: '',
  judgeUuid: '',
  currentTask: '',
  nextTask: '',
  tasks: [],
  tasksCompleted: [],
  tasksResults: [],
  participantResults: [],
  judgesList: {},
  socketsCache: [],
  socketsSent: [],
  taskManagerQueue: []
};

export interface ServerToClientEvents {
  noArg: () => void;
  basicEmit: (a: number, b: string, c: Buffer) => void;
  withAck: (d: string, callback: (e: number) => void) => void;
}

export interface ClientToServerEvents {
  adjudicatorRoundResults: (arg0:TaskResults) => void;
}

export interface InterServerEvents {
  ping: () => void;
}

export interface SocketData {
  name: string;
  age: number;
}


const workSlice = createSlice({
  name: 'work',
  initialState,
  reducers: {
    updateTimetable(state, action: PayloadAction<object>) {
      state.timetable = action.payload;
    },
    updateJudgeLetter(state, action: PayloadAction<string>) {
      state.judgeLetter = action.payload;
    },
    updateJudgeName(state, action: PayloadAction<string>) {
      state.judgeName = action.payload;
    },
    updateJudgeUuid(state, action: PayloadAction<string>) {
      state.judgeUuid = action.payload;
    },
    updateJudgesList(state, action: PayloadAction<object>) {
      state.judgesList = action.payload;
    },
    updateTaskManagerQueue(state, action: PayloadAction<ManagerItem[]>) {
      state.taskManagerQueue = action.payload;

      const nextActive = action.payload.find((_) => _.active === true);
      if (nextActive !== undefined) {
        state.nextTask = nextActive.roundUuid;
      } else {
        state.nextTask = '';
      }
    },
    updateCurrentTask(
      state,
      action: PayloadAction<ManagerItem | string | null>
    ) {
      if (action.payload === null) {
        state.currentTask = '';
      } else if (typeof action.payload === 'string') {
        state.currentTask = action.payload;
      } else {
        state.currentTask = action.payload.roundUuid;
      }
    },
    updateNextTask(state, action: PayloadAction<ManagerItem | string | null>) {
      if (action.payload === null) {
        state.nextTask = '';
      } else if (typeof action.payload === 'string') {
        state.nextTask = action.payload;
      } else {
        state.nextTask = action.payload.roundUuid;
      }
    },
    updateTask(state, action: PayloadAction<Task>) {
      // const currentAdjudicator = state.judgeUuid;
      const task = action.payload;
      const search = state.tasks.find(
        (_: Task) =>
          _.roundUuid === action.payload.roundUuid &&
          _.adjudicatorUuid === task.adjudicatorUuid
        // &&
        // _.ttItemUuid === task.ttItemUuid
      );

      if (typeof search === 'undefined') {
        state.tasks = state.tasks.concat(action.payload);
      } else {
        state.tasks = state.tasks
          .filter(
            (_: Task) =>
              !(
                (
                  _.roundUuid === search.roundUuid &&
                  _.adjudicatorUuid === task.adjudicatorUuid
                )
                // &&
                // _.ttItemUuid === task.ttItemUuid
              )
          )
          .concat(action.payload);
      }
    },
    updateTaskResults(state, action: PayloadAction<TaskResults>) {
      // const newTasks = state.tasksResults.filter((task) => {
      //   task.roundUuid !== action.payload.roundUuid;
      // });
      // state.tasksResults = [...newTasks, action.payload];
      const taskResults = action.payload;
      // console.log('tr', taskResults);
      // console.log('tresults', current(state))
      const search =
        state.tasksResults.length > 0
          ? state.tasksResults.find(
              (_: TaskResults) =>
                _.roundUuid === taskResults.roundUuid &&
                _.adjudicatorUuid === taskResults.adjudicatorUuid
              // &&
              // _.ttItemUuid === taskResults.ttItemUuid
            )
          : undefined;

      if (typeof search === 'undefined') {
        state.tasksResults = state.tasksResults.concat(taskResults);
      } else {
        state.tasksResults = state.tasksResults
          .filter(
            (_: TaskResults) =>
              !(
                (
                  _.roundUuid === search.roundUuid &&
                  _.adjudicatorUuid === taskResults.adjudicatorUuid
                )
                // &&
                // _.ttItemUuid === taskResults.ttItemUuid
              )
          )
          .concat(taskResults);
      }
    },
    updateParticipantResults(
      state,
      action: PayloadAction<ParticipantResult[]>
    ) {
      const currentAdjudicator = state.judgeUuid;

      if (typeof state.participantResults === 'undefined') {
        state.participantResults = [];
      }
      if (typeof state.participantResults[currentAdjudicator] === 'undefined') {
        state.participantResults = {
          ...state.participantResults,
          [currentAdjudicator]: []
        };
      }
      action.payload.forEach((newPR) => {
        const search = state.participantResults[currentAdjudicator].find(
          (_: ParticipantResult) =>
            _.danceUuid === newPR.danceUuid &&
            _.uuid === newPR.uuid &&
            _.roundUuid === newPR.roundUuid
        );

        if (typeof search === 'undefined') {
          state.participantResults[currentAdjudicator] =
            state.participantResults[currentAdjudicator].concat(newPR);
        } else {
          state.participantResults[currentAdjudicator] =
            state.participantResults[currentAdjudicator]
              // .filter((_: Task) => _.adjudicatorUuid !== task.adjudicatorUuid)
              .filter(
                (_: ParticipantResult) =>
                  !(
                    _.danceUuid === search.danceUuid &&
                    _.uuid === search.uuid &&
                    _.roundUuid === search.roundUuid
                  )
              )
              .concat(newPR);
        }
      });
    },
    clearParticipantResultsForRound(state, action: PayloadAction<Task>) {
      const currentAdjudicator = state.judgeUuid;
      const { dances } = action.payload;
      if (typeof state.participantResults === 'undefined') {
        state.participantResults = [];
      }
      if (typeof state.participantResults[currentAdjudicator] === 'undefined') {
        state.participantResults = {
          ...state.participantResults,
          [currentAdjudicator]: []
        };
      }
      let tmp = state.participantResults[currentAdjudicator];
      dances.forEach((d) => {
        tmp = tmp.filter(
          (_: ParticipantResult) =>
            !(
              _.roundUuid === action.payload.roundUuid && _.danceUuid === d.uuid
            )
        );
      });
      state.participantResults[currentAdjudicator] = tmp;
      // state.participantResults[currentAdjudicator] = state.participantResults[
      //   currentAdjudicator
      // ].filter(
      //   (_: ParticipantResult) => !(_.roundUuid === action.payload.roundUuid)
      // );
    },
    clearData(state) {
      state.timetable = {};
      state.judgeLetter = '';
      state.judgeName = '';
      state.judgeUuid = '';
      state.currentTask = '';
      state.nextTask = '';
      state.tasks = [];
      state.tasksCompleted = [];
      state.tasksResults = [];
      state.participantResults = [];
      state.judgesList = {};
      state.socketsCache = [];
      state.socketsSent = [];
      state.taskManagerQueue = [];
    },
    removeTask(state, action: PayloadAction<Task>) {
      const task = action.payload;
      const search = state.tasks.find(
        (_: Task) =>
          _.roundUuid === action.payload.roundUuid &&
          _.adjudicatorUuid === task.adjudicatorUuid
      );
      if (search !== undefined) {
        state.tasks = state.tasks.filter(
          (_) =>
            !(
              _.roundUuid === search.roundUuid &&
              _.adjudicatorUuid === task.adjudicatorUuid
            )
        );
      }
    },
    updateTaskCompleted(state, action: PayloadAction<Task>) {
      const task = action.payload;
      const search = state.tasksCompleted.find(
        (_: Task) =>
          _.roundUuid === action.payload.roundUuid &&
          _.adjudicatorUuid === task.adjudicatorUuid
      );
      if (typeof search === 'undefined') {
        state.tasksCompleted = state.tasksCompleted.concat(task);
      } else {
        state.tasksCompleted = state.tasksCompleted
          .filter(
            (_: Task) =>
              _.roundUuid !== search.roundUuid &&
              _.adjudicatorUuid === task.adjudicatorUuid
          )
          .concat(task);
      }
    },
    removeTaskCompleted(state, action: PayloadAction<Task>) {
      const task = action.payload;

      const newTasksCompleted = state.tasksCompleted.filter(
        (_) =>
          task.roundUuid !== task.roundUuid &&
          _.adjudicatorUuid === task.adjudicatorUuid
      );
      state.tasksCompleted = newTasksCompleted;
    },
    addSocketToCache(state, action: PayloadAction<SocketElement>) {
      const newSocket = Object.assign({}, action.payload, {
        uuid: uuidv4()
      });
      state.socketsCache.push(newSocket);
    },
    removeSocketFromCache(state, action: PayloadAction<SocketElement>) {
      const newCache = state.socketsCache.filter((socket) => {
        socket.uuid !== action.payload.uuid;
      });
      state.socketsCache = newCache;
    },
    addSocketToSent(state, action: PayloadAction<SocketElement>) {
      if (action.payload.uuid !== undefined && action.payload.uuid !== null) {
        state.socketsSent.push(action.payload);
      } else {
        const newSocket = Object.assign({}, action.payload, {
          uuid: uuidv4()
        });
        state.socketsSent.push(newSocket);
      }
    },
    removeSocketFromSent(state, action: PayloadAction<SocketElement>) {
      const newSent = state.socketsSent.filter((socket) => {
        socket.uuid !== action.payload.uuid;
      });
      state.socketsSent = newSent;
    }
  }
});

export const addEmptyTaskResults = createAppAsyncThunk(
  'work/addRoundTasksFromRound',
  async function (data: null, thunkApi) {
    const { getState, dispatch } = thunkApi;
    const currentTaskUuid = getState().work.currentTask;
    const adjudicatorUuid = getState().work.judgeUuid;
    const currentTask = getState().work.tasks.find(
      (_: Task) =>
        _.roundUuid === currentTaskUuid && _.adjudicatorUuid === adjudicatorUuid
    );
    if (currentTaskUuid !== null && currentTask !== undefined) {
      // console.log(currentTaskUuid);
      // console.log(getState().work.tasks);
      const participantResults: ParticipantResult[] = [];
      currentTask?.dances.forEach((dance) => {
        currentTask.participants.forEach((participant) => {
          const newResult =
            currentTask.judgingSystem === SYSTEM.MARKS ? 0 : null;
          const newParticipantResult: ParticipantResult = {
            uuid: participant.uuid,
            roundUuid: currentTask.roundUuid,
            danceUuid: dance.uuid,
            result: newResult,
            judgingSystem: currentTask.judgingSystem
          };
          participantResults.push(newParticipantResult);
        });
      });
      const newTaskResults: TaskResults = {
        uuid: uuidv4(),
        roundUuid: currentTask.roundUuid,
        ttItemUuid: currentTask.ttItemUuid,
        judgingSystem: currentTask.judgingSystem,
        participantResults: [],
        status: TASK.IN_PROGRESS,
        adjudicatorUuid: adjudicatorUuid
      };
      dispatch(updateTaskResults(newTaskResults));
      dispatch(updateParticipantResults(participantResults));
    }
  }
);
export const updateParticipantResultsAndPrepareCurrent = createAppAsyncThunk(
  'work/updateParticipantResultsAndPrepareCurrent',
  async function (newParticipantResult: ParticipantResult, thunkApi) {
    const { dispatch } = thunkApi;
    // await Promise.all([
    //   dispatch(updateParticipantResults([newParticipantResult]))
    // ]).then(() => prepareCurrentRoundResults(TASK.IN_PROGRESS));
    dispatch(updateParticipantResults([newParticipantResult]));
    // prepareCurrentRoundResults(TASK.IN_PROGRESS);
  }
);
export const prepareCurrentRoundResults = createAppAsyncThunk(
  'work/prepareCurrentRoundResults',
  async function (status: TaskType, thunkApi) {
    const { getState, dispatch } = thunkApi;

    let newStatus: TaskType = TASK.IN_PROGRESS;
    if (status !== null) {
      newStatus = status;
    }
    const currentTaskUuid = getState().work.currentTask;
    const adjudicatorUuid = getState().work.judgeUuid;

    const currentTask = getState().work.tasks.find(
      (_: Task) =>
        _.roundUuid === currentTaskUuid && _.adjudicatorUuid === adjudicatorUuid
    );

    // console.log('foundTask', currentTask);
    if (currentTask !== undefined) {
      const currentFetchedResult = getState().work.tasksResults.find(
        (_) =>
          _.roundUuid === currentTask?.roundUuid &&
          _.adjudicatorUuid === adjudicatorUuid
        // &&
        // _.ttItemUuid === currentTask?.ttItemUuid
      );
      let currentResult = currentFetchedResult;
      if (currentFetchedResult === undefined) {
        const newTaskResults: TaskResults = {
          uuid: uuidv4(),
          roundUuid: currentTask.roundUuid,
          ttItemUuid: currentTask.ttItemUuid,
          judgingSystem: currentTask.judgingSystem,
          participantResults: [],
          status: newStatus,
          adjudicatorUuid: adjudicatorUuid
        };
        currentResult = newTaskResults;
      }
      if (currentTaskUuid !== null && currentResult !== undefined) {
        const participantResults = getState().work.participantResults[
          adjudicatorUuid
        ].filter(
          (_: ParticipantResult) => _.roundUuid === currentTask.roundUuid
        );
        const newResults = Object.assign({}, currentResult, {
          participantResults: participantResults
        });
        // console.log('pr', participantResults);
        // console.log('nr', newResults.participantResults);
        dispatch(updateTaskResults(newResults));
        const newTask: Task = Object.assign({}, currentTask, {
          status: newStatus,
          taskResults: newResults
        });
        if (newStatus === TASK.DONE) {
          const taskManagerQueue = getState().work.taskManagerQueue;

          const index = taskManagerQueue.findIndex(
            (_) => _.roundUuid === newTask.roundUuid
          );
          if (taskManagerQueue[index + 1] !== undefined) {
            dispatch(updateCurrentTask(taskManagerQueue[index + 1]));
          } else {
            dispatch(updateCurrentTask(null));
          }

          dispatch(updateTaskCompleted(newTask));
          dispatch(removeTask(newTask));
        } else {
          dispatch(updateTask(newTask));
        }
      }
    }
  }
);

// Export actions
export const {
  updateTimetable,
  updateJudgeLetter,
  updateJudgeName,
  updateJudgesList,
  updateCurrentTask,
  updateNextTask,
  updateJudgeUuid,
  updateTaskManagerQueue,
  updateTask,
  updateTaskResults,
  updateParticipantResults,
  clearParticipantResultsForRound,
  removeTask,
  clearData,
  updateTaskCompleted,
  removeTaskCompleted,
  addSocketToCache,
  addSocketToSent,
  removeSocketFromCache,
  removeSocketFromSent
} = workSlice.actions;

//selectors
export const selectTimetable = (state: RootState) => {
  return state.work.timetable;
};
export const selectJudge = (state: RootState) => {
  return {
    letter: state.work.judgeLetter,
    name: state.work.judgeName,
    uuid: state.work.judgeUuid
  };
};
export const selectJudgesList = (state: RootState) => {
  return state.work.judgesList;
};
export const selectCurrentTaskUuid = (state: RootState) => {
  return state.work.currentTask;
};
export const selectNextTaskUuid = (state: RootState) => {
  return state.work.nextTask;
};
export const selectCurrentTask = (state: RootState) => {
  const currentTaskUuid = state.work.currentTask;
  const currentAdjudicator = state.work.judgeUuid;
  if (currentTaskUuid !== null && currentTaskUuid !== '') {
    return state.work.tasks.find(
      (_: Task) =>
        _.roundUuid === currentTaskUuid &&
        _.adjudicatorUuid === currentAdjudicator
    );
  }
  return null;
};
export const selectNextTask = (state: RootState) => {
  const nextTaskUuid = state.work.nextTask;
  const currentAdjudicator = state.work.judgeUuid;

  if (nextTaskUuid !== null && nextTaskUuid !== '') {
    return state.work.tasks.find(
      (_: Task) =>
        _.roundUuid === nextTaskUuid && _.adjudicatorUuid === currentAdjudicator
    );
  }
  return null;
};
export const selectTaskManagerQueue = (state: RootState) => {
  return state.work.taskManagerQueue;
};
export const selectActiveTask = (state: RootState) => {
  return state.work.taskManagerQueue.find((item: ManagerItem) => item.active);
};
export const selectTasks = (state: RootState) => {
  return state.work.tasks;
};

export const selectAllTaskResults = (state: RootState) => {
  const searchRes = state.work.tasksResults;
  return searchRes === undefined ? null : searchRes;
};

export const selectCurrentTaskResults = (state: RootState) => {
  const currentTask = selectCurrentTask(state);
  const currentAdjudicator = state.work.judgeUuid;
  // console.log('search');
  // console.log(state.work.tasksResults);
  const searchRes = state.work.tasksResults?.find(
    (item: TaskResults) =>
      item.roundUuid === currentTask?.roundUuid &&
      item.adjudicatorUuid === currentAdjudicator
  );
  return searchRes === undefined ? null : searchRes;
};
export const selectCurrentTaskParticipantResults = (state: RootState) => {
  const currentTask = selectCurrentTask(state);
  const adjudicatorUuid = state.work.judgeUuid;
  if (state.work.participantResults[adjudicatorUuid] !== undefined) {
    const searchRes = state.work.participantResults[adjudicatorUuid].filter(
      (item: ParticipantResult) => item.roundUuid === currentTask?.roundUuid
    );
    return searchRes === undefined ? [] : searchRes;
  } else {
    return [];
  }
};
export const selectTasksCompleted = (state: RootState) => {
  return state.work.tasksCompleted;
};
export const selectSocketsCache = (state: RootState) => {
  return state.work.socketsCache;
};
export const selectSocketsSent = (state: RootState) => {
  return state.work?.socketsSent;
};
// Export reducer
export default workSlice.reducer;
