import {
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  ThunkDispatch
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import homeReducer from '../redux/homeSlice';
import settingsReducer from '../redux/settingsSlice';
import workReducer from '../redux/workSlice';
import {
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['home', 'settings', 'work']
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    home: homeReducer,
    settings: settingsReducer,
    work: workReducer
  })
);
const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'CLEAR_ALL') {
    storage.removeItem('persist:root');
    // return persistedReducer(undefined, action);
    state = undefined;
  }
  return persistedReducer(state, action);
};
export const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      // },
      // immutableCheck: false
      immutableCheck: false,
      serializableCheck: false,
    })
  ]
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
