import React from 'react';
import { Routes, Route } from 'react-router';
import { ROUTES } from '../constants/routes';
import loadable from '@loadable/component';

const Home = loadable(() =>
  import(/* webpackChunkName: "HomeChunk" */ '../screens/Home')
);
const Setup = loadable(() =>
  import(/* webpackChunkName: "SetupChunk" */ '../screens/Setup')
);
const Adjudicator = loadable(() =>
  import(/* webpackChunkName: "AdjudicatorChunk" */ '../screens/Adjudicator')
);
const Current = loadable(() =>
  import(/* webpackChunkName: "CurrentChunk" */ '../screens/Current')
);
class AppRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.SETUP} element={<Setup />}></Route>
        <Route path={ROUTES.ADJUDICATOR} element={<Adjudicator />}></Route>
        <Route path={ROUTES.CURRENT} element={<Current />}></Route>
      </Routes>
    );
  }
}

export default AppRoutes;
